import {
  callable,
  projects,
  plans,
  doc,
  ref_doc,
  set_doc,
  list,
  load,
  update,
  remove,
  where,
  query
} from '@/plugins/serverless.js'

import { hydrate_list } from '@/store/utils'
import { load_photos } from '@/plugins/utils'
import { by_project_author } from '@/store/story'
export const actions = {
  reset({ commit }) {
    commit('set_project', {})
    commit('set_form', {})
    commit('set_photos', [])
    commit('set_project_users', [])
    commit('set_participants', [])
    commit('set_files', [])
    commit('set_plan', {})
    commit('set_questions', [])
  },
  set_new({ commit }, new_project) {
    commit('set_project', new_project)
  },
  set_form({ commit }, form) {
    commit('set_form', form)
  },
  async create({ commit }, { project, me }) {
    const ref = ref_doc(projects)
    const rp_ref = ref_doc(plans)

    project.companies = []
    for (const id of me.companyIds)
      project.companies.push(doc(`companies/${id}`))

    project.id = ref.id
    project.stories_count = 0
    project.contact = {
      name: project.contact.name,
      email: project.contact.email,
      phone: project.contact.phone
    }
    let res = await set_doc(ref, project)
    commit('set_project', project)
    res = await set_doc(rp_ref, {
      id: rp_ref.id,
      name: project.name,
      project: ref
    })
    commit('set_plan', { res })
    this.$notifier.showMessage({ content: `${project.name} created` })
    sessionStorage.removeItem('relations')
    callable('relations')()
  },
  async by_slug({ commit }, slug) {
    const snap = await list(by_slug(slug))
    const projects = []
    if (snap.empty) return
    snap.forEach(doc => projects.push(doc.data()))
    commit('set_project', projects[0])
  },

  async load_project({ commit }, id) {
    if (id) {
      const snap = await load(doc(`/projects/${id}`))
      if (snap.exists()) commit('set_project', snap.data())
      const photos = await load_photos({ type: 'project', id: id })
      commit('set_photos', photos)
    }
  },

  async load_projects({ commit }) {
    console.log('load_projects is expensive')
    var prjs = []
    const snap = await list(projects)
    prjs = hydrate_list(snap.docs)
    commit('set_projects', prjs)
  },
  async load_company_projects({ commit }, pyld) {
    if (pyld && pyld.companyIds?.length >= 1) {
      const snap = await list(by_company(pyld.companyIds))
      const projects = hydrate_list(snap)
      commit('set_projects', projects)
    }
  },
  async load_participants({ commit }, pyld) {
    const log_name = `load_participants: is-expensive: ${pyld.id}`
    console.time(log_name)
    if (pyld && pyld.id) {
      let participants = []
      const snap = await load(doc(`projects/${pyld.id}`))
      if (snap.exists() && snap.data().participants)
        participants = snap.data().participants

      let existing = []
      const load_list = participants.map(async p => {
        const snap = await load(p.user)
        if (!snap.exists()) return
        const author = snap.data()

        const stories_snap = await list(by_project_author(pyld.id, p.user.id))
        if (!stories_snap.empty) {
          const story = hydrate_list(stories_snap)[0]
          author.status = story.status
          author.story_id = story.id
        }

        existing.push(author)
      })
      await Promise.all(load_list)
      console.timeEnd(log_name)
      commit('set_participants', existing)
    }
  },
  async load_plan({ commit }, id) {
    let plan = {}
    const snap = await list(by_plan(id))
    if (!snap.empty) {
      snap.forEach(doc => {
        plan = doc.data()
      })
      commit('set_plan', plan)
      commit('set_questions', plan.questions)
    }
  },
  async update_project({ commit }, project) {
    await update(doc(`projects/${project.id}`), project)
    commit('set_project', project)
    callable('relations')()
  },
  async delete_project({ commit }, pyld) {
    await remove(doc(`projects/${pyld.project.id}`))
    await remove(doc(`projects/${pyld.plan.id}`))
    callable('relations')()
    commit('set_project', {})
    commit('set_plan', {})
  },
  async update_plan({ commit }, pyld) {
    await update(doc(`plans/${pyld.plan.id}`), pyld.plan)
    commit('set_plan', pyld.plan)
    this.$notifier.showMessage({ content: 'Updated' })
  }
}
export const state = () => ({
  project: {},
  form: {},
  projects: [],
  managers: [],
  participants: [],
  files: [],
  plan: {},
  photos: [],
  questions: [],
  screens: []
})
export const mutations = {
  set_project(state, project) {
    state.project = project
  },
  set_form(state, form) {
    state.form = form
  },
  set_projects(state, projects) {
    state.projects = projects
  },
  set_project_users(state, project_users) {
    state.project_users = project_users
  },
  set_participants(state, participants) {
    state.participants = participants
  },
  set_files(state, files) {
    state.files = files
  },
  set_plan(state, plan) {
    state.plan = plan
  },
  set_questions(state, questions) {
    state.questions = questions
  },
  set_photos(state, photos) {
    state.photos = photos
  }
}
export const getters = {
  get_projects: state => state.projects,
  get_form: state => state.form,
  get_project: state => state.project,
  get_participants: state => state.participants,
  get_users: state => state.projectUsers,
  get_plan: state => state.plan,
  get_photos: state => state.photos,
  get_questions: state => state.questions,
  get_screens: state => state.screens
}

export const by_slug = slug => query(projects, where('slug', '==', slug))

export const by_company = ids =>
  query(projects, where('companyIds', 'array-contains-any', ids))
export const by_plan = id =>
  query(plans, where('project', '==', doc(`projects/${id}`)))
